import classNames from "classnames";
import PropTypes from "prop-types";

import AtomCreditCardIcon from "components/source/atoms/atom-credit-card-icon";
import { getFormattedCardDetails } from "helpers/payment-method-helpers";

import AtomPill from "components/source/atoms/atom-pill";
import { PaymentMethods, APPLE_PAY_STRING } from "rtr-constants";

const AtomManagePaymentMethodCardWithStatusActions = ({
  card,
  selectedProfileId,
  selectPaymentProfile,
  togglePaymentMethodRemoval,
  isBillingMethod = false,
}) => {
  const { id, cardType, subType } = card || {};
  const { cardName, ccString, expirationDate } = getFormattedCardDetails(card);
  const isDefaultedMethod = selectedProfileId === id;
  const isApplePay = subType === PaymentMethods.subType.APPLE_PAY;

  const makeDefaultOrRecurringText = isBillingMethod ? "Make billing" : "Make primary";
  const makeDefaultOrRecurringTestId = isBillingMethod ? "make-billing" : "make-primary";

  return (
    <li
      className={classNames("manage-payment-profile-card", {
        "manage-payment-profile-card--default": isDefaultedMethod,
      })}>
      <div data-heap-redact-text="true">
        <div className="atom-payment-profile-card__type" aria-label="Payment method details">
          <AtomCreditCardIcon cardType={cardType} cardSubType={subType} />
          <div className="atom-payment-profile-card__obfuscated-number" aria-label="Last 4 digits of payment card">
            {!isApplePay ? ccString : APPLE_PAY_STRING}
          </div>
        </div>
        {!isApplePay && (
          <div className="atom-payment-profile-card__info" aria-label="Card information">
            <div aria-label="Name on card">{cardName}</div>
            <div aria-label="Card expiration date">{expirationDate}</div>
          </div>
        )}
        {card?.default && (
          <div className="atom-payment-profile-card__default" aria-label="Default payment method label">
            <AtomPill additionalClassName={"payment-method-pill"}>Primary</AtomPill>
          </div>
        )}
        {card?.recurring && (
          <div className="atom-payment-profile-card__default" aria-label="Default payment method label">
            <AtomPill additionalClassName={"payment-method-pill"}>Membership Billing</AtomPill>
          </div>
        )}
      </div>
      {!isDefaultedMethod && (
        <div className="atom-payment-profile-card__actions" aria-label="Payment method actions">
          {/* Only allow CC to be primary/recurring */}
          {!isApplePay && (
            <button
              className="atom-payment-profile-card__make-default underline"
              onClick={() => selectPaymentProfile(id)}
              aria-label="Action to make this payment method the default payment method"
              data-test-id={makeDefaultOrRecurringTestId}>
              {makeDefaultOrRecurringText}
            </button>
          )}
          <button
            className="atom-payment-profile-card__remove underline"
            aria-label="Action to remove this payment method"
            onClick={() => togglePaymentMethodRemoval(id)}
            data-test-id="remove-payment-method">
            Remove
          </button>
        </div>
      )}
    </li>
  );
};

AtomManagePaymentMethodCardWithStatusActions.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cardType: PropTypes.string,
    recurring: PropTypes.bool,
    default: PropTypes.bool,
  }),
  selectedProfileId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectPaymentProfile: PropTypes.func,
  togglePaymentMethodRemoval: PropTypes.func,
  isBillingMethod: PropTypes.bool,
};

export default AtomManagePaymentMethodCardWithStatusActions;

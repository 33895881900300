import React from "react";
import CheckboxInputPair from "components/source/shared/checkbox-input-pair";
import PropTypes from "prop-types";

const propTypes = {
  checked: PropTypes.bool,
  readOnly: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  updateElement: PropTypes.func.isRequired,
};

class MakeDefaultCheckbox extends React.Component {
  constructor(props) {
    super(props);

    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);

    this.state = {
      checked: this.props.checked,
    };
  }

  componentDidMount() {
    // If the box is not checked, a change event will never fire, which means
    // that values will never go to the Redux store and won't be submitted.
    // This ensures that an event fires at least once and there will be a value.
    // We use state rather than props because state is set in the constructor before componentDidMount is called.
    const initialValue = this.state.checked ? "true" : "false";
    this.props.updateElement(initialValue, true, this.props.name, {});
  }

  pickOnChangeFunc() {
    // If this component is read-only, we do not want the user to be able to change it.
    // But! There is no such thing as a read-only HTML checkbox input.
    // We are doing a few things to make it very very hard for users to change this component if it's "read-only":
    // 1. Use the `inputProps` to set a `readonly` attribute on the checkbox input
    // (good for semantics but has no effect: see https://stackoverflow.com/questions/155291/can-html-checkboxes-be-set-to-readonly)
    // 2. Set the css for the `.read-only` class to `pointer-events: none`
    // (definitely not foolproof; makes the element unclickable IF css loads before user clicks)
    // 3. In this function, we do not pass an onChange function to the CheckboxInputPair if it is read only.
    // (The "react way" to make an input read-only; requires a `readOnly` attribute on the input or else
    // you will get React warnings in the browser.)
    if (!this.props.readOnly) {
      return this.handleCheckboxClick;
    }
  }

  handleCheckboxClick(e) {
    const value = e.target.checked ? "true" : "false";
    this.props.updateElement(value, true, this.props.name, {});
    this.setState({
      checked: !this.state.checked,
    });
  }

  wrapperClass() {
    if (this.props.readOnly) {
      return "read-only";
    }
    return "";
  }

  render() {
    return (
      <CheckboxInputPair
        wrapperClass={this.wrapperClass()}
        id={this.props.id}
        name={this.props.name}
        label={this.props.label}
        value="true"
        uncheckedValue="false"
        checked={this.state.checked}
        inputProps={{ readOnly: this.props.readOnly }}
        onChange={this.pickOnChangeFunc()}
      />
    );
  }
}

MakeDefaultCheckbox.propTypes = propTypes;

export default MakeDefaultCheckbox;

import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import VerifyAuthState from "./VerifyAuthState";
import { AUTH_STATES, clientSideErrorMessages, PaymentMethods } from "rtr-constants";

import { withFlagsAndExperiments, flagsAndExperimentNames } from "components/source/hoc/with-flags-and-experiments";

import MoleculePaymentProfileSelect from "components/source/molecules/molecule-payment-profile-select";
import ClosableModal from "components/source/shared/closable-modal";

import { getBillingPaymentMethod, getPrimaryPaymentMethod } from "helpers/payment-method-helpers";
import { displayModal } from "actions/shared-actions";
import {
  loadPaymentProfiles,
  submitPaymentMethod,
  submitPaymentMethodWithToken,
  updateDefaultAndRemovePaymentMethods,
} from "actions/payment-method-actions";

const noop = () => {}; // NOSONAR

class ManagePaymentMethods extends React.Component {
  static propTypes = {
    modalName: PropTypes.string.isRequired, // ChangePaymentMethodModalNames

    displayManageCards: PropTypes.bool,
    setAsDefault: PropTypes.bool, // primary or billing depending on context
    showMakeDefaultCheckbox: PropTypes.bool, // primary
    displayedModalName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isBillingMethod: PropTypes.bool,
    isKifahBag: PropTypes.bool,

    displayModal: PropTypes.func,
    submitDefaultPaymentMethod: PropTypes.func,
    updateDefaultAndRemovePaymentMethods: PropTypes.func,
    updateDefaultPaymentMethod: PropTypes.func,
    onCreatePaymentMethodFailure: PropTypes.func,
    onCreatePaymentMethodSuccess: PropTypes.func,
    onUpdatePaymentMethodsFailure: PropTypes.func,
    onUpdatePaymentMethodsSuccess: PropTypes.func,

    paymentProfiles: PropTypes.array, // TODO: arrayOf(paymentProfilePropTypes)
    flagsAndExperiments: PropTypes.object,
  };

  static defaultProps = {
    onCreatePaymentMethodSuccess: noop,
    onCreatePaymentMethodFailure: noop,
    onUpdatePaymentMethodsSuccess: noop,
    onUpdatePaymentMethodsFailure: noop,
    showMakeDefaultCheckbox: true,
    setAsDefault: false,
    displayManageCards: false,
    isKifahBag: false,
  };

  state = {};

  useDefaultAs() {
    if (this.props.flagsAndExperiments?.[flagsAndExperimentNames.COMET_SF_ENABLE_RECURRING_BILLING]) {
      return this.props.isBillingMethod ? PaymentMethods.paymentType.RECURRING : PaymentMethods.paymentType.DEFAULT;
    } else {
      return "default";
    }
  }

  // #################### CREATE ####################
  createNewPaymentMethod = values => {
    // default is the legacy naming
    // will remain in place for now
    // can be default (primary) or recurring (billing)
    this.props.submitDefaultPaymentMethod(
      values,
      paymentProfile => {
        this.handleClose();
        this.props.onCreatePaymentMethodSuccess(paymentProfile);
      },
      this.props.onCreatePaymentMethodFailure
    );
  };

  // #################### UPDATE ####################
  updatePaymentMethod = () => {
    const onSuccess = newPaymentMethod => {
      this.handleClose();
      this.props.onUpdatePaymentMethodsSuccess(newPaymentMethod);
    };

    const onFailure = () => {
      this.setState({
        // selectedProfileId refers to the id of the profile to be updated (primary or billing)
        selectedProfileId: null,
        applyPaymentMethodError: clientSideErrorMessages.changeDefaultPaymentMethod.defaultError,
      });
      this.props.onUpdatePaymentMethodsFailure();
    };

    this.setState({
      applyPaymentMethodError: null,
    });

    const defaultType = this.useDefaultAs();

    if (this.state.selectedProfileId) {
      this.props.updateDefaultPaymentMethod(
        {
          url: "/paymentMethods",
          vals: {
            id: this.state.selectedProfileId,
            [defaultType]: true,
          },
        },
        onSuccess,
        onFailure
      );
    } else {
      onSuccess();
    }
  };

  // #################### UPDATE/DELETE ####################
  // handles the bulk operation of changing default card
  // as well as removing any methods that have been selected
  updateDefaultAndOrRemovePaymentMethod = (data = {}) => {
    const {
      newDefaultPaymentMethodID,
      originalDefaultPaymentMethodID,
      removedPaymentMethods,
      clearRemovedPaymentMethods = noop,
    } = data;

    const onSuccess = () => {
      this.handleClose();
    };

    const onFailure = msg => {
      this.setState({
        selectedProfileId: null,
        applyPaymentMethodError: msg || null,
      });

      clearRemovedPaymentMethods(); // MoleculeManagePaymentProfileCards
    };

    this.setState({
      applyPaymentMethodError: null,
    });
    const defaultType = this.useDefaultAs();
    this.props.updateDefaultAndRemovePaymentMethods({
      values: {
        newDefaultPaymentMethodID,
        originalDefaultPaymentMethodID,
        updateData: {
          url: "/paymentMethods",
          vals: {
            id: newDefaultPaymentMethodID,
            [defaultType]: true,
          },
        },
        removeData: {
          url: "/paymentMethods",
          vals: {
            ids: Object.keys(removedPaymentMethods).filter(key => removedPaymentMethods[key]),
          },
        },
      },
      onSuccess,
      onFailure,
    });
  };

  handleFormSetAsDefaultCallback = values => {
    if (this.props.setAsDefault) {
      const defaultType = this.useDefaultAs();
      values[defaultType] = true;
    }
    return values;
  };

  setSelectedPaymentProfileId = id => {
    this.setState({ selectedProfileId: id });
  };

  selectedPaymentProfile = () => {
    if (this.state.selectedProfileId) return this.state.selectedProfileId;

    return this.props.isBillingMethod
      ? getBillingPaymentMethod(this.props.paymentProfiles)?.id
      : getPrimaryPaymentMethod(this.props.paymentProfiles)?.id;
  };

  handleClose = () => {
    this.setState({
      selectedProfileId: null,
      applyPaymentMethodError: null,
    });
    this.props.displayModal(false);
  };

  render() {
    const isOpen = this.props.displayedModalName === this.props.modalName;

    return (
      <VerifyAuthState
        isReadyToPromptForAuth={isOpen}
        authModalProps={{ callback: () => this.props.displayModal(this.props.modalName) }}
        requiredAuthState={AUTH_STATES.AUTHORIZED}>
        <ClosableModal additionalClassName={"manage-payment-methods"} isOpen={isOpen} onRequestClose={this.handleClose}>
          <MoleculePaymentProfileSelect
            addressSectionSubhead="Add Billing Address"
            applyPaymentMethodError={this.state.applyPaymentMethodError}
            displayManageCards={this.props.displayManageCards}
            handleFormElementValuesCallback={this.handleFormSetAsDefaultCallback}
            selectedProfileId={this.selectedPaymentProfile()}
            selectPaymentProfile={this.setSelectedPaymentProfileId}
            shouldClearFieldsOnMount={true}
            showMakeDefaultCheckbox={this.props.showMakeDefaultCheckbox}
            submitPaymentMethod={this.createNewPaymentMethod} // create
            usePaymentMethod={this.updatePaymentMethod} // update
            updateDefaultAndRemovePaymentMethods={this.updateDefaultAndOrRemovePaymentMethod}
            isBillingMethod={this.props.isBillingMethod ?? false}
            isKifahBag={this.props.isKifahBag ?? false}
          />
        </ClosableModal>
      </VerifyAuthState>
    );
  }
}

const mapStateToProps = state => {
  return {
    paymentProfiles: state.paymentProfiles,
    displayedModalName: state.displayedModal,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    updateDefaultPaymentMethod: async (values, onSuccess = noop, onFailure = noop) => {
      const isPatchCall = true;

      try {
        await dispatch(submitPaymentMethod(values, isPatchCall));

        dispatch(loadPaymentProfiles());
        onSuccess(values?.vals);
      } catch (e) {
        onFailure();
      }
    },
    updateDefaultAndRemovePaymentMethods: async ({ values, onSuccess = noop, onFailure = noop }) => {
      try {
        await dispatch(updateDefaultAndRemovePaymentMethods(values));
        onSuccess();
      } catch (errorMessage) {
        onFailure(errorMessage);
      }
    },
    displayModal: values => {
      dispatch(displayModal(values));
    },
    submitDefaultPaymentMethod: (values, onSuccess = noop, onFailure = noop) => {
      const onSuccessCallback = newPaymentProfile => {
        dispatch(loadPaymentProfiles());
        onSuccess(newPaymentProfile);
      };

      dispatch(submitPaymentMethodWithToken(values, onSuccessCallback, onFailure));
    },
  };
};

export default compose(
  withFlagsAndExperiments(flagsAndExperimentNames.COMET_SF_ENABLE_RECURRING_BILLING),
  connect(mapStateToProps, mapDispatchToProps)
)(ManagePaymentMethods);

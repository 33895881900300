import React from "react";
import PropTypes from "prop-types";
import { idPropType } from "components/propTypes";
import AtomManagePaymentMethodCardWithStatusActions from "components/source/atoms/atom-payment-method-card-with-status-actions";
import AtomPaymentMethodRemovedCard from "components/source/atoms/atom-payment-method-removed-card";
import AtomFullWidthButton from "components/source/atoms/atom-full-width-button";

class MoleculeManagePaymentProfileCards extends React.Component {
  static propTypes = {
    isSubmitting: PropTypes.bool,
    selectPaymentProfile: PropTypes.func,
    selectedProfileId: idPropType,
    sortedPaymentProfiles: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateDefaultAndRemovePaymentMethods: PropTypes.func,
    renderAddNewCard: PropTypes.func,
    isBillingMethod: PropTypes.bool,
    recurringBillingEnabled: PropTypes.bool, // COMETS-4818 temporary
  };

  state = {
    removedPaymentMethods: {},
  };

  togglePaymentMethodRemoval = id => {
    this.setState(prevState => {
      return {
        removedPaymentMethods: {
          ...prevState.removedPaymentMethods,
          [id]: !prevState.removedPaymentMethods[id],
        },
      };
    });
  };

  getOriginalDefaultOrRecurringPaymentMethodId = () => {
    const originalDefaultPaymentMethod = this.props.sortedPaymentProfiles.find(profile =>
      this.props.isBillingMethod && this.props.recurringBillingEnabled ? profile.recurring : profile.default
    );
    return originalDefaultPaymentMethod?.id || null;
  };

  isButtonDisabled = () => {
    const isButtonDisabled =
      !this.state.removedPaymentMethods &&
      this.props.selectedProfileId === this.getOriginalDefaultOrRecurringPaymentMethodId();

    return isButtonDisabled || this.props.isSubmitting;
  };

  submitPaymentMethodChanges = () => {
    const clearRemovedPaymentMethods = () => {
      this.setState({
        removedPaymentMethods: {},
      });
    };

    const data = {
      newDefaultPaymentMethodID: this.props.selectedProfileId,
      originalDefaultPaymentMethodID: this.getOriginalDefaultOrRecurringPaymentMethodId(),
      removedPaymentMethods: this.state.removedPaymentMethods,
      clearRemovedPaymentMethods,
    };
    this.props.updateDefaultAndRemovePaymentMethods(data);
  };

  render() {
    const buttonText = this.props.isSubmitting ? "Saving..." : "Save Changes";

    return (
      <div>
        <ul className="molecule-payment-profile-select__cards manage-payment-profile-cards">
          {this.props.sortedPaymentProfiles.map(profile => {
            const id = profile?.id || "";
            const hasBeenMarkedForRemoval = this.state.removedPaymentMethods[id] ?? false;

            if (hasBeenMarkedForRemoval) {
              return (
                <AtomPaymentMethodRemovedCard
                  key={id}
                  card={profile}
                  togglePaymentMethodRemoval={this.togglePaymentMethodRemoval}
                />
              );
            }

            return (
              <AtomManagePaymentMethodCardWithStatusActions
                key={id}
                card={profile}
                selectedProfileId={this.props.selectedProfileId}
                selectPaymentProfile={this.props.selectPaymentProfile}
                togglePaymentMethodRemoval={this.togglePaymentMethodRemoval}
                isBillingMethod={this.props.isBillingMethod}
              />
            );
          })}
        </ul>
        {this.props.renderAddNewCard()}
        <AtomFullWidthButton
          buttonText={buttonText}
          onClick={this.submitPaymentMethodChanges}
          disabled={this.isButtonDisabled()}
          dataTestId="save-changes"
        />
      </div>
    );
  }
}

export default MoleculeManagePaymentProfileCards;

import React from "react";
import _ from "underscore";
import AnimatedTextInputWrapper from "components/source/shared/animated-text-input-wrapper";
import AnimatedZipInput from "components/source/shared/animated_inputs/animated-zip-input";
import AnimatedStreetAddressInput from "components/source/shared/animated_inputs/animated-street-address-input";
import AnimatedStatesDropdown from "components/source/shared/animated_inputs/animated-states-dropdown";
import AnimatedPhoneInput from "components/source/shared/animated_inputs/animated-phone-input";
import OptionalAptInput from "components/source/checkout/forms/optional-apt-input";
import MakeDefaultCheckbox from "components/source/checkout/forms/make-default-checkbox.jsx";
import BillingSameAsShippingCheckbox from "components/source/checkout/forms/billing-same-as-shipping-checkbox";
import {
  iframeActions,
  iframeRemoteKeys,
  clientSideErrorMessages,
  formAutoCompleteCategories,
  formAutoCompleteSettings,
  formLabels,
  validations,
} from "rtr-constants";
import { SecureCreditCardEntryPage } from "routes";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class NewCreditCardFormFields extends React.Component {
  static propTypes = {
    // props from parent component
    additionalClassName: PropTypes.string,
    addressSectionSubhead: PropTypes.string,
    billingIsSameAsShipping: PropTypes.bool,
    cardSectionDek: PropTypes.node,
    cardSectionSubhead: PropTypes.string,
    displayBillingSameAsShippingCheckbox: PropTypes.bool,
    elements: PropTypes.objectOf(
      PropTypes.shape({
        isRequired: PropTypes.bool,
        isValid: PropTypes.bool,
        value: PropTypes.string,
      })
    ),
    // names used for HTML name attribute on input and Redux store keys
    formElementNames: PropTypes.shape({
      apt: PropTypes.string.isRequired,
      ccFirstName: PropTypes.string.isRequired,
      ccLastName: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      makeDefault: PropTypes.string,
      phone: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
    }).isRequired,
    makeDefaultCheckbox: PropTypes.shape({
      checked: PropTypes.bool,
      readOnly: PropTypes.bool,
    }),
    onChangeWithSuppressedErrors: PropTypes.func.isRequired, // special callback for inputs with regex validation
    stateKey: PropTypes.string.isRequired, // which part of the redux store to read / write
    toggleBillingSameAsShipping: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired, // callback when inputs change/blur

    /* eslint-disable react/sort-prop-types */
    // props from mapStateToProps
    errors: PropTypes.shape({
      form: PropTypes.string,
      message: PropTypes.string,
    }),
    formIsValid: PropTypes.bool,
    /* eslint-enable react/sort-prop-types */
  };

  static defaultProps = {
    // The displayBillingSameAsShippingCheckbox prop refers
    // to whether or not to show the checkbox.
    displayBillingSameAsShippingCheckbox: false,
  };

  baseClassName = "subform-form-fields new-credit-card-form-fields";

  constructor(props) {
    super(props);

    this.setIframeSecureCreditCardEntry = elem => {
      this.iframeSecureCreditCardEntry = elem;
    };
  }

  className = () => {
    return this.props.additionalClassName
      ? this.baseClassName
      : `${this.baseClassName} ${this.props.additionalClassName}`;
  };

  toggleBillingAddressFields = val => {
    // When the 'Billing address is same as shipping address' is checked
    // or unchecked, we hide / show the address fields within the billing step.
    // We also populate or clear the billing step address fields.
    this.props.toggleBillingSameAsShipping(val);
  };

  inputData(name) {
    return {
      name,
      id: `billing-${name}`,
      value: this.getValueFromElements(name),
      error: this.getErrorFromState(name),
      required: this.getRequiredFromElements(name),
      autoComplete: formAutoCompleteSettings(name, formAutoCompleteCategories.Billing),
    };
  }

  getValueFromElements = elementName => {
    return this.props.elements?.[elementName]?.value || "";
  };

  getRequiredFromElements(elementName) {
    // eslint-disable-next-line react/prop-types
    return this.props.elements?.[elementName]?.isRequired || false;
  }

  getErrorFromState(elementName) {
    return this.props.errors?.[elementName] || "";
  }

  optionalAptInput = () => {
    const { apt } = this.props.formElementNames;

    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <OptionalAptInput
        {...this.inputData(apt)}
        label={formLabels.apt}
        validationErrorMsg={clientSideErrorMessages.formValidation.streetAddress}
        validateInput={value => !value.match(validations.poBox)}
        onChangeCallback={this.props.updateElement}
        onBlurCallback={this.props.updateElement}
      />
      /* eslint-enable react/jsx-props-no-spreading */
    );
  };

  makeDefaultCheckbox = formElementName => {
    if (this.props.makeDefaultCheckbox) {
      const { checked, readOnly } = this.props.makeDefaultCheckbox;

      return (
        <MakeDefaultCheckbox
          checked={checked}
          readOnly={readOnly}
          id={formElementName}
          name={formElementName}
          label={formLabels.defaultPayment}
          updateElement={this.props.updateElement}
        />
      );
    }
  };

  billingSameAsShippingCheckbox() {
    if (this.props.displayBillingSameAsShippingCheckbox) {
      return (
        <BillingSameAsShippingCheckbox
          checked={this.props.billingIsSameAsShipping}
          name={"billingSameAsShipping"}
          id={"billingSameAsShipping"}
          label={"Billing address is the same as shipping address"}
          onChange={this.toggleBillingAddressFields}
        />
      );
    }
  }

  cardSectionSubhead() {
    if (this.props.cardSectionSubhead) {
      return <div className="subform-subhead">{this.props.cardSectionSubhead}</div>;
    }
  }

  addressSectionSubhead() {
    if (this.props.addressSectionSubhead) {
      return <div className="subform-subhead">{this.props.addressSectionSubhead}</div>;
    }
  }

  renderAddressSection() {
    const {
      formElementNames: { street, apt, city, state, zipCode, phone },
    } = this.props;

    if (this.props.billingIsSameAsShipping) {
      return null;
    }

    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <div className="subform-section">
        {this.addressSectionSubhead()}

        <AnimatedStreetAddressInput
          {...this.inputData(street)}
          onChangeCallback={this.props.updateElement}
          onBlurCallback={this.props.updateElement}
        />

        {this.optionalAptInput(apt)}

        <div className="subform-field-pair">
          <AnimatedTextInputWrapper
            {...this.inputData(city)}
            label={formLabels.city}
            onChangeCallback={this.props.updateElement}
            onBlurCallback={this.props.updateElement}
            validationErrorMsg="Enter a city"
          />

          <AnimatedStatesDropdown
            {...this.inputData(state)}
            label={formLabels.state}
            autofill={true}
            onChangeCallback={this.props.updateElement}
            onBlurCallback={this.props.updateElement}
          />
        </div>

        <div className="subform-field-pair">
          <AnimatedZipInput
            {...this.inputData(zipCode)}
            label={formLabels.zipCode}
            onChangeCallback={_.partial(this.props.onChangeWithSuppressedErrors, _, _, _, _, this.props.formIsValid)}
            onBlurCallback={this.props.updateElement}
          />

          <AnimatedPhoneInput
            {...this.inputData(phone)}
            label={formLabels.phoneNumber}
            onChangeCallback={this.props.updateElement}
            onBlurCallback={this.props.updateElement}
            validationErrorMsg={clientSideErrorMessages.formValidation.phoneNumber}
          />
        </div>
      </div>
      /* eslint-enable react/jsx-props-no-spreading */
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props, nextProps)) {
      const { errors, formIsValid } = nextProps;

      window.iframeMessenger.send(iframeRemoteKeys.SECURE_CREDIT_CARD, iframeActions.PARENT_COMPONENT_PROPS_UPDATED, {
        errors,
        formIsValid,
      });
    }
  }

  componentDidMount() {
    // Set the iframe as the remote location for any messages sent from the top-level page.
    if (this.iframeSecureCreditCardEntry?.contentWindow) {
      window.iframeMessenger.registerRemote(
        iframeRemoteKeys.SECURE_CREDIT_CARD,
        this.iframeSecureCreditCardEntry.contentWindow
      );

      const { errors, formIsValid } = this.props;

      window.iframeMessenger.send(iframeRemoteKeys.SECURE_CREDIT_CARD, iframeActions.PARENT_COMPONENT_PROPS_UPDATED, {
        errors,
        formIsValid,
      });

      // Receive messages from the iframe whenever the credit card form's
      // onChangeCallback or updateElement is invoked
      window.iframeMessenger.addHandler(
        iframeRemoteKeys.PARENT,
        iframeActions.ON_CHANGE_CALLBACK,
        (action, payload) => {
          const { value, isValid, name, inputObj } = payload;

          this.props.onChangeWithSuppressedErrors(value, isValid, name, inputObj, this.props.formIsValid);
        }
      );

      window.iframeMessenger.addHandler(iframeRemoteKeys.PARENT, iframeActions.ON_BLUR_CALLBACK, (action, payload) => {
        const { value, isValid, name, inputObj } = payload;

        this.props.updateElement(value, isValid, name, inputObj);
      });
    }
  }

  componentWillUnmount() {
    // These events are registered within the iframe via the IframeCreditCardForm component. However, because the iframe's
    // source code (our secure-credit-card-entry-app build file) never "unmounts", a new bundle is loaded each time this component mounts
    // and creates a new iframe element. This next block cleans up the handlers registered within that frame's context.
    const iframeContentWindow = this.iframeSecureCreditCardEntry?.contentWindow;
    if (iframeContentWindow) {
      [iframeActions.PARENT_COMPONENT_PROPS_UPDATED, iframeActions.SAVE_PAYMENT_METHOD_USING_EPROTECT].forEach(
        action => {
          iframeContentWindow.iframeMessenger?.removeHandlers?.(iframeRemoteKeys.SECURE_CREDIT_CARD, action);
        }
      );
    }

    // These events are registered on the parent frame. Some are used to handle updating the parent form that wraps the iframe credit
    // card fields (i.e. first and last name, billing address) and others register hooks to respond to various stages of the payment
    // method submittal process (i.e. either Vantiv errors or success / failures once the Vantiv token and payment profile is submitted to our
    // backend within the iframe)
    [
      iframeActions.LOG_ACTION,
      iframeActions.ON_BLUR_CALLBACK,
      iframeActions.ON_CHANGE_CALLBACK,
      iframeActions.HookNames.BEFORE_EPROTECT_TOKEN_GENERATION,
      iframeActions.HookNames.ON_EPROTECT_ERROR,
      iframeActions.HookNames.ON_EPROTECT_NOT_LOADED_ERROR,
      iframeActions.HookNames.ON_EPROTECT_TIMEOUT,
      iframeActions.HookNames.ON_SAVE_PAYMENT_METHOD_SUCCESS,
      iframeActions.HookNames.ON_SAVE_PAYMENT_METHOD_FAILURE,
    ].forEach(action => {
      window.iframeMessenger?.removeHandlers?.(iframeRemoteKeys.PARENT, action);
    });
  }

  renderSecureCreditCardEntry() {
    return (
      <iframe
        title="secure-credit-card-entry"
        src={SecureCreditCardEntryPage}
        className="subform-form-fields__sensitive-fields"
        ref={this.setIframeSecureCreditCardEntry}
      />
    );
  }

  render() {
    const {
      cardSectionDek,
      formElementNames: { ccFirstName, ccLastName, makeDefault },
    } = this.props;

    // TODO: This should ideally focus on the next field which has no value. This is applicable
    // for an "edit" scenario where we might have some fields but should also require the user to
    // reenter their CVV.

    return (
      <div className={this.className()} data-test-id="new-credit-card-form-fields">
        <div className="subform-section">
          {this.cardSectionSubhead()}

          {cardSectionDek}

          {/* eslint-disable react/jsx-props-no-spreading */}
          <div className="subform-field-pair">
            <AnimatedTextInputWrapper
              {...this.inputData(ccFirstName)}
              label="First Name"
              onChangeCallback={this.props.updateElement}
              onBlurCallback={this.props.updateElement}
              validationErrorMsg="Enter a first name"
              focus={true}
            />

            <AnimatedTextInputWrapper
              {...this.inputData(ccLastName)}
              label="Last Name"
              onChangeCallback={this.props.updateElement}
              onBlurCallback={this.props.updateElement}
              validationErrorMsg="Enter a last name"
            />
          </div>
          {/* eslint-enable react/jsx-props-no-spreading */}

          {this.renderSecureCreditCardEntry()}
        </div>

        {this.renderAddressSection()}
        {this.billingSameAsShippingCheckbox()}
        {this.makeDefaultCheckbox(makeDefault)}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const stateKey = ownProps.stateKey || "paymentMethod";
  const subformState = state[stateKey] || {};

  // if the toggle is hidden, force the entry of an address
  const billingIsSameAsShipping = ownProps.displayBillingSameAsShippingCheckbox && subformState.billingIsSameAsShipping;

  return {
    formIsValid: subformState.isValid,
    errors: subformState.errors,
    billingIsSameAsShipping,
  };
};

export default connect(mapStateToProps)(NewCreditCardFormFields);

import React from "react";
import PropTypes from "prop-types";
import AnimatedTextInputWrapper from "components/source/shared/animated-text-input-wrapper";

export default class OptionalAptInput extends React.Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    error: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onBlurCallback: PropTypes.func.isRequired,
    onChangeCallback: PropTypes.func.isRequired,
    required: PropTypes.bool,
    type: PropTypes.string.isRequired,
    validateInput: PropTypes.func,
    validationErrorMsg: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    type: "text",
  };

  componentDidMount() {
    // Set optional input to true on mount. Any subsequent user input will also be considered true
    this.props.onChangeCallback("", true, this.props.name, {});
  }

  render() {
    const {
      id,
      name,
      type,
      label,
      required,
      validationErrorMsg,
      validateInput,
      onChangeCallback,
      onBlurCallback,
      error,
      value,
      autoComplete,
    } = this.props;

    return (
      <AnimatedTextInputWrapper
        id={id}
        name={name}
        type={type}
        label={label}
        required={required}
        validationErrorMsg={validationErrorMsg}
        validateInput={validateInput}
        onChangeCallback={onChangeCallback}
        onBlurCallback={onBlurCallback}
        error={error}
        value={value}
        autoComplete={autoComplete}
      />
    );
  }
}

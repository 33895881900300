import { getFormattedCardDetails } from "helpers/payment-method-helpers";

const AtomPaymentMethodRemovedCard = ({ card, togglePaymentMethodRemoval }) => {
  const { last4Digits } = getFormattedCardDetails(card);
  const cardId = card?.id;
  const notification = last4Digits
    ? `Your card ending in ${last4Digits} will be removed.`
    : "Your card will be removed.";

  return (
    <div className="manage-payment-profile-card-removal" role="listitem">
      <div
        className="manage-payment-profile-card-removal__notification"
        aria-label="Notification for payment method removal">
        {notification}
      </div>
      <button
        className="manage-payment-profile-card-removal__undo-action"
        aria-label="Action to undo payment method removal"
        onClick={() => togglePaymentMethodRemoval(cardId)}>
        Cancel change
      </button>
    </div>
  );
};

export default AtomPaymentMethodRemovedCard;

import React from "react";
import CheckboxInputPair from "components/source/shared/checkbox-input-pair";
import PropTypes from "prop-types";

const propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default class BillingSameAsShippingCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
  }

  handleCheckboxClick(e) {
    const value = e.target.checked ? true : false;
    this.props.onChange(value);
  }

  render() {
    return (
      <CheckboxInputPair
        id={this.props.id}
        name={this.props.name}
        label={this.props.label}
        value={true}
        uncheckedValue={false}
        checked={this.props.checked}
        onChange={this.handleCheckboxClick}
      />
    );
  }
}

BillingSameAsShippingCheckbox.propTypes = propTypes;

import React from "react";
import PropTypes from "prop-types";

import AtomHighlightTextButton from "components/source/atoms/atom-highlight-text-button";
import AtomRadioCard from "components/source/atoms/atom-radio-card";
import AtomCreditCardIcon from "components/source/atoms/atom-credit-card-icon";

import { APPLE_PAY_STRING } from "rtr-constants";

const propTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  expiration: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  isDefault: PropTypes.bool,
  isExpired: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool,
  cardType: PropTypes.string.isRequired,
  cardSubType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  triggerEditView: PropTypes.func.isRequired,
};

class AtomPaymentProfileRadioCard extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  // cardNumber returned at XXXXXXXX1234
  // We would like it to have no "X" characters and just the last 4 digits
  formatCardNumber() {
    return `Ending in ${this.props.cardNumber.replace(/X*/, "")}`;
  }

  // expiration comes back as "YYYY-MM" and we'd like to display it as "MM / YYYY"
  formatExpiration() {
    return this.props.expiration.split("-").reverse().join(" / ");
  }

  // Turn On when we support this in code.
  editPaymentMethod() {
    const ALWAYS_FALSE = false; // For eslint

    if (ALWAYS_FALSE) {
      return <AtomHighlightTextButton buttonText={"Edit"} onClick={this.props.triggerEditView} />;
    }
  }

  onChange(event) {
    event.stopPropagation();
    this.props.onChange(this.props.id);
  }

  renderExpiration() {
    const { isExpired } = this.props;

    if (isExpired) {
      return (
        <span className="messages">
          <span className="error">Expired {this.formatExpiration()}</span>
        </span>
      );
    }

    return `Exp ${this.formatExpiration()}`;
  }

  displayMarkup() {
    const defaultText = this.props.isDefault ? "Default" : "";
    const isApplePay = this.props.cardSubType === "applePay";

    return (
      <div data-heap-redact-text="true">
        <div className="atom-payment-profile-card__type">
          <AtomCreditCardIcon cardType={this.props.cardType} cardSubType={this.props.cardSubType} />
          <div>
            {isApplePay ? (
              APPLE_PAY_STRING
            ) : (
              <div className="atom-payment-profile-card__obfuscated-number">{this.formatCardNumber()}</div>
            )}
          </div>
        </div>
        <div className="atom-payment-profile-card__default">{defaultText}</div>
        <div className="atom-payment-profile-card__info">
          <div>{`${this.props.firstName} ${this.props.lastName}`}</div>
          <div>{this.renderExpiration()}</div>
        </div>
        {this.editPaymentMethod()}
      </div>
    );
  }

  render() {
    return (
      <AtomRadioCard
        checked={this.props.isChecked}
        onChange={this.onChange}
        value={this.props.id}
        id={this.props.id}
        name={"paymentProfile"}>
        {this.displayMarkup()}
      </AtomRadioCard>
    );
  }
}

AtomPaymentProfileRadioCard.propTypes = propTypes;

export default AtomPaymentProfileRadioCard;

import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import PropTypes from "prop-types";
import { addressPropType, idPropType } from "components/propTypes";

import {
  EVENTS_CUSTOM_CASTLE,
  formElementNames,
  checkout,
  featureFlags,
  flagsAndExperimentNames,
  PaymentMethods,
} from "rtr-constants";
import NewCreditCardFormFields from "components/source/checkout/forms/new-credit-card-form-fields";

import AtomPaymentProfileRadioCard from "components/source/atoms/atom-payment-profile-radio-card";
import AtomHighlightTextButton from "components/source/atoms/atom-highlight-text-button";
import AtomFullWidthButton from "components/source/atoms/atom-full-width-button";
import MoleculeManagePaymentProfileCards from "components/source/molecules/molecule-manage-payment-profile-cards";
import GenericSubform from "components/source/checkout/forms/generic-subform";

import paymentMethodsActions from "actions/payment-method-actions";
import { blockForMasqueradeSelector } from "helpers/checkout-helpers";
import * as castleHelper from "helpers/castle-helper";
import { paymentProfilesOfSubType, paymentProfilesOfSubTypes } from "helpers/payment-method-helpers";

import FlagsAndExperimentsActions from "actions/flags-and-experiments-actions";
import { withFlagsAndExperiments } from "components/source/hoc/with-flags-and-experiments";
import { selectFeatureFlagEnabled } from "selectors/featureFlagSelectors";

const { paymentMethodSubTypes } = checkout;

// Overarching consideration — if this also houses the edit and new, how should this be represented?

// Current implementation of this components provides a "selected" id to a __TODO__ key on the redux store.
// This feature supports edit, new and selecting of existing profiles.

// API
// - Accepts payment methods
// - Provides a paymentProfileId to provide to orders

export const displayStates = {
  ADD_CARD: "ADD_CARD",
  EDIT_CARD: "EDIT_CARD",
  SELECT_CARD: "SELECT_CARD",
  // this state is for displaying tiles that allow user to set default card and remove cards
  MANAGE_CARD: "MANAGE_CARD",
};

export const AddNewPaymentMethod = GenericSubform(NewCreditCardFormFields);

class MoleculePaymentProfileSelectComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.object.isRequired,

    addresses: PropTypes.arrayOf(addressPropType),
    applyPaymentMethodError: PropTypes.string,
    useCurrentPaymentMethodCTAText: PropTypes.string,
    useSelectedPaymentMethodCTAText: PropTypes.string,
    /* eslint-disable react/sort-prop-types */
    // for NewCreditCardFormFields
    addressSectionSubhead: PropTypes.string,
    cardSectionSubhead: PropTypes.string,

    selectedProfileId: idPropType,
    shippingAddressIds: PropTypes.arrayOf(idPropType),
    paymentProfiles: PropTypes.arrayOf(PropTypes.object),
    flagsAndExperiments: PropTypes.object,

    useShippingAddress: PropTypes.func,
    toggleBillingSameAsShipping: PropTypes.func,
    selectPaymentProfile: PropTypes.func.isRequired,
    validateBeforeSubmitAction: PropTypes.func,
    usePaymentMethod: PropTypes.func,
    submitPaymentMethod: PropTypes.func,
    updateDefaultAndRemovePaymentMethods: PropTypes.func,
    handleFormElementValuesCallback: PropTypes.func,
    onSelectExistingProfile: PropTypes.func,

    billingStepIsValid: PropTypes.bool,
    blockForMasquerade: PropTypes.bool,
    displayAddForm: PropTypes.bool,
    displayManageCards: PropTypes.bool,
    hideNewExistingPaymentToggle: PropTypes.bool,
    hideUseSelectedAddressButton: PropTypes.bool,
    showFormMargin: PropTypes.bool,
    showMakeDefaultCheckbox: PropTypes.bool,
    showSelectExistingProfileCTA: PropTypes.bool,
    displayBillingSameAsShippingCheckbox: PropTypes.bool,
    isPaymentProfilesLoading: PropTypes.bool,
    shouldClearFieldsOnMount: PropTypes.bool,
    updatingPaymentMethods: PropTypes.bool,
    userDeletePaymentMethodsFF: PropTypes.bool,
    isBillingMethod: PropTypes.bool,
    isKifahBag: PropTypes.bool,
  };

  static defaultProps = {
    useCurrentPaymentMethodCTAText: "Use Current Billing Information",
    useSelectedPaymentMethodCTAText: "Use This Billing Information",
    updateDefaultAndRemovePaymentMethods: () => {},
    displayManageCards: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      display: this.initialDisplayState(),
      /* We set `hasMakeDefaultCheckbox` in initial state because
       * each instance of the form either has the checkbox or not;
       * we don't want this to change throughout the use of the form.
       * I.e., if we only show the checkbox when the user has >= 1 payment methods,
       * it doesn't appear on pageload for a new user but then appears momentarily
       * once the new payment method is created.
       */
      hasMakeDefaultCheckbox: this.shouldShowMakeDefaultCheckbox(),
      initSelectedProfileId: this.getSelectedProfileId(),
    };
  }

  componentDidMount() {
    /* When the component mounts, we check to see if we have a shipping
     * address associated with the order here. If we do, and
     * this.props.displayBillingSameAsShippingCheckbox is true,
     * we dispatch the 'useShippingAddress' here, which
     * will populate the billing step address fields with the
     * shipping address values.
     * It will also set billingStep.billingIsSameAsShipping = true.
     */
    const { shippingAddressIds, useShippingAddress, addresses, displayBillingSameAsShippingCheckbox } = this.props;

    const addressId = shippingAddressIds?.[0] ?? null;
    const shippingAddress = addressId
      ? addresses?.find(address => address?.id?.toString() === addressId?.toString())
      : null;

    if (shippingAddress && displayBillingSameAsShippingCheckbox) {
      useShippingAddress(shippingAddress);
    }

    this.props.dispatch(
      FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames.COMET_SF_ENABLE_RECURRING_BILLING)
    );
  }

  componentDidUpdate(prevProps) {
    // Fixes issue where the modal is launched after authentication but before payment profiles are finished loading,
    // which caused the modal to default to add new payment method because the paymentProfiles state was empty.
    if (prevProps.isPaymentProfilesLoading && !this.props.isPaymentProfilesLoading) {
      this.setState({ display: this.initialDisplayState() });
    }
  }

  getPaymentMethods() {
    if (this.props.isBillingMethod || this.props.isKifahBag) {
      return paymentProfilesOfSubTypes(this.props.paymentProfiles, [
        checkout.paymentMethodSubTypes.CREDIT_CARD,
        checkout.paymentMethodSubTypes.APPLE_PAY,
      ]);
    } else {
      return paymentProfilesOfSubType(this.props.paymentProfiles, checkout.paymentMethodSubTypes.CREDIT_CARD);
    }
  }

  initialDisplayState() {
    // Render ADD_CARD view is requested.
    if (this.props.displayAddForm) {
      return displayStates.ADD_CARD;
    }

    // Render ADD_CARD view if no payment methods are present.
    if (this.shouldDisplayAddForm()) {
      return displayStates.ADD_CARD;
    }

    if (this.props.displayManageCards && this.props.userDeletePaymentMethodsFF) {
      return displayStates.MANAGE_CARD;
    }

    // Render SELECT_CARD in all other states
    return displayStates.SELECT_CARD;
  }

  shouldShowSelectExistingProfileCTA() {
    const { billingStepIsValid, paymentProfiles, showSelectExistingProfileCTA } = this.props;

    // we should know this already, but just in case
    if (!paymentProfiles) return false;

    // if the step is closed (which we probably shouldn't know here...)
    if (billingStepIsValid) {
      return;
    }

    return showSelectExistingProfileCTA;
  }

  shouldShowMakeDefaultCheckbox() {
    const { showMakeDefaultCheckbox, paymentProfiles } = this.props;

    /* If showMakeDefaultCheckbox isn't explicitly specified in props, don't show
     * the checkbox if the user has no payment methods of subType 'creditCard'.
     */
    if (showMakeDefaultCheckbox === null || typeof showMakeDefaultCheckbox === "undefined") {
      const creditCards = paymentProfilesOfSubType(paymentProfiles, paymentMethodSubTypes.CREDIT_CARD);
      return creditCards?.length > 0;
    }

    /* If showMakeDefaultCheckbox has been explicitly
     * specified -- either true or false --
     * we should honor that.
     */
    return showMakeDefaultCheckbox;
  }

  optionalClasses() {
    return this.props.showFormMargin ? "" : "molecule-payment-profile-select--no-margin";
  }

  shouldDisplayAddForm() {
    const { paymentProfiles, blockForMasquerade } = this.props;
    return !blockForMasquerade && (!paymentProfiles || paymentProfiles.length === 0);
  }

  onPaymentMethodSelect = profileId => {
    // Update store with selected payment method id
    this.props.selectPaymentProfile(profileId);
  };

  onPaymentSubmit = values => {
    /**
     * CE-2550: if this is the billing modal, we need to set recurring to true here, so this
     * payment method can immediately be set for the user's membership charges.
     */
    if (this.props.isBillingMethod) {
      values.vals.recurring = true;
    }

    this.props.submitPaymentMethod(values);
    castleHelper.logCustomEvent({ name: EVENTS_CUSTOM_CASTLE.PAYMENT_METHOD_UPDATE });
  };

  onUseSelectedCard = () => {
    // Try to get the selected address id from props
    // (This will be present if the user has clicked on an address card)
    let { selectedProfileId } = this.props;

    if (!selectedProfileId) {
      // If we are here, the user has not clicked on an payment method card yet
      // This will get us the id of the card that is checked when molecule first rendered
      selectedProfileId = this.getSelectedProfileId();
    }
    this.props.usePaymentMethod(selectedProfileId);
  };

  triggerPaymentForm = paymentProfileId => {
    const nextState = {};
    if (paymentProfileId) {
      nextState.paymentProfileId = paymentProfileId;
      nextState.display = displayStates.EDIT_CARD;
    } else {
      nextState.display = displayStates.ADD_CARD;
    }
    this.setState(nextState);
  };

  renderApplyPaymentMethodError() {
    const { applyPaymentMethodError } = this.props;

    if (!applyPaymentMethodError) {
      return;
    }

    return <div className="messages molecule-payment-profile-select__errors">{applyPaymentMethodError}</div>;
  }

  renderAddNewCardButton = () => {
    if (this.props.hideNewExistingPaymentToggle || this.props.blockForMasquerade) {
      return;
    }

    return (
      <AtomFullWidthButton
        onClick={this.triggerPaymentForm}
        buttonText="+ Add a new Credit Card"
        dataTestId="add-new-primary-card"
        secondaryButtonStyle={true}
      />
    );
  };

  renderSelectExistingProfileCTA() {
    if (this.props.hideNewExistingPaymentToggle) {
      return;
    }

    /*
     * This is for the case in which the user has existing profiles,
     * but none of them have been associated with the orderGroup at checkout
     * page load. This can happen when a customer has an expired credit card as
     * their default payment method.
     */
    const { onSelectExistingProfile } = this.props;
    const showSelectExistingProfileCTA = this.shouldShowSelectExistingProfileCTA();

    if (!showSelectExistingProfileCTA || !onSelectExistingProfile) {
      return;
    }

    return (
      <div className="subform__select-existing-address">
        Or
        <AtomHighlightTextButton onClick={onSelectExistingProfile} buttonText={"select an existing credit card"} />
      </div>
    );
  }

  // kifah/bag
  renderUseSelectedPaymentMethodCTA() {
    if (this.props.hideUseSelectedAddressButton) return;
    const buttonText =
      this.state.initSelectedProfileId !== this.getSelectedProfileId()
        ? this.props.useSelectedPaymentMethodCTAText
        : this.props.useCurrentPaymentMethodCTAText;

    return (
      <div className="molecule-payment-profile-select__add">
        <AtomFullWidthButton buttonText={buttonText} onClick={this.onUseSelectedCard} />
      </div>
    );
  }

  getSelectedProfileId(paymentProfiles) {
    if (this.props.selectedProfileId) return this.props.selectedProfileId;

    const sortedPaymentProfiles = paymentProfiles || this.sortedByDefaultPaymentProfile() || [];
    const firstItem = sortedPaymentProfiles?.[0] ?? null;

    // COMETS-4818
    if (!this.props.flagsAndExperiments?.[flagsAndExperimentNames.COMET_SF_ENABLE_RECURRING_BILLING])
      return (firstItem?.default && firstItem?.id) || this.mostRecentPaymentProfile();

    const profileType =
      this.props.isBillingMethod || this.props.isKifahBag
        ? PaymentMethods.paymentType.RECURRING
        : PaymentMethods.paymentType.DEFAULT;
    return (firstItem?.[profileType] && firstItem?.id) || this.mostRecentPaymentProfile();
  }

  mostRecentPaymentProfile() {
    const lastItem = this.props.paymentProfiles?.sort()?.slice(-1)?.[0];
    return lastItem?.id;
  }

  sortedByDefaultPaymentProfile() {
    // COMETS-4818
    if (!this.props.flagsAndExperiments?.[flagsAndExperimentNames.COMET_SF_ENABLE_RECURRING_BILLING]) {
      return this.props.paymentProfiles
        ?.filter(profile => profile.subType === PaymentMethods.subType.CREDIT_CARD) // in case of applePay getting through
        ?.sort((profileA, profileB) => profileB.default - profileA.default);
    }

    if (this.props.isBillingMethod || this.props.isKifahBag) {
      return this.props.paymentProfiles
        ?.filter(
          profile =>
            [PaymentMethods.subType.APPLE_PAY, PaymentMethods.subType.CREDIT_CARD].includes(profile.subType) &&
            !(profile.subType === PaymentMethods.subType.APPLE_PAY && profile.recurring === false)
        )
        ?.sort((profileA, profileB) => {
          // Sort by `recurring` property first, then by `default` property
          return profileB.recurring - profileA.recurring || profileB.default - profileA.default;
        });
    } else {
      return this.props.paymentProfiles
        ?.filter(profile => profile.subType === PaymentMethods.subType.CREDIT_CARD) // in case of applePay getting through
        ?.sort((profileA, profileB) => profileB.default - profileA.default);
    }
  }

  renderRadioCard(profile, selectedProfileId) {
    const { id, firstName, lastName, expiration, cardNumber, cardType, subType, expired } = profile;

    const isDefault = (profile?.default || profile?.recurring) ?? false;
    const isSelected = selectedProfileId === id;

    return (
      <AtomPaymentProfileRadioCard
        key={id}
        id={id}
        firstName={firstName}
        lastName={lastName}
        expiration={expiration}
        cardNumber={cardNumber}
        isDefault={isDefault}
        isChecked={isSelected}
        cardType={cardType}
        cardSubType={subType}
        isExpired={expired}
        onChange={this.onPaymentMethodSelect}
        triggerPaymentForm={this.triggerPaymentForm}
      />
    );
  }

  getHeaderText(isSelectState) {
    if (isSelectState) return "Select Payment Option";
    return this.props.isBillingMethod ? "Membership Billing" : "Primary Payment Method";
  }

  getSubheaderText(isSelectState) {
    if (this.props.isBillingMethod) return "This card will be charged monthly for your membership.";

    return isSelectState
      ? "This payment method will be applied to the current order" // COMETS-5082 aoife: confirm this copy KIFAH
      : "This is the primary payment option when you buy or reserve.";
  }

  content() {
    // loading payment profiles
    if (this.props.isPaymentProfilesLoading) {
      return <output className="loading" aria-busy="true" />;
    }

    // select or manage cards
    if (this.state.display === displayStates.SELECT_CARD || this.state.display === displayStates.MANAGE_CARD) {
      const isSelectState = this.state.display === displayStates.SELECT_CARD;
      const sortedPaymentProfiles = this.sortedByDefaultPaymentProfile() || [];
      const selectedProfileId = this.getSelectedProfileId(sortedPaymentProfiles);
      const headerText = this.getHeaderText(isSelectState);
      const subHeaderText = this.getSubheaderText(isSelectState);
      return (
        <div>
          <div className="molecule-payment-profile-select__header">{headerText}</div>
          {subHeaderText && <div className="molecule-payment-profile-select__subheader">{subHeaderText}</div>}
          {this.renderApplyPaymentMethodError()}
          {isSelectState ? (
            <div>
              <ul className="molecule-payment-profile-select__cards">
                {sortedPaymentProfiles.map(profile => this.renderRadioCard(profile, selectedProfileId))}
              </ul>
              {this.renderUseSelectedPaymentMethodCTA()}
              {this.renderAddNewCardButton()}
            </div>
          ) : (
            <MoleculeManagePaymentProfileCards
              selectPaymentProfile={this.props.selectPaymentProfile}
              sortedPaymentProfiles={sortedPaymentProfiles}
              selectedProfileId={selectedProfileId}
              updateDefaultAndRemovePaymentMethods={this.props.updateDefaultAndRemovePaymentMethods}
              isSubmitting={this.props.updatingPaymentMethods}
              renderAddNewCard={this.renderAddNewCardButton}
              isBillingMethod={this.props.isBillingMethod}
              // COMETS-4818 temporary flag
              recurringBillingEnabled={
                this.props.flagsAndExperiments?.[flagsAndExperimentNames.COMET_SF_ENABLE_RECURRING_BILLING]
              }
            />
          )}
        </div>
      );
    }

    // Editing or adding a card;
    // If showMakeDefaultCheckbox is true, we should show the checkbox with those default values
    // If false then, we should set makeDefaultCheckbox to false to hide the checkbox.
    const makeDefaultCheckbox = this.state.hasMakeDefaultCheckbox ? { checked: true, readOnly: false } : false;

    if (this.props.blockForMasquerade) {
      return <div id="masq_block_message">Adding Credit Cards is blocked for Masquerading</div>;
    }

    const paymentProfile = this.getPaymentMethods()?.find(profile => profile.id === this.state.paymentProfileId);

    return (
      <div>
        <AddNewPaymentMethod
          name="addNewPaymentMethodModal"
          action="/paymentMethods"
          stateKey="billingStep"
          addressSectionSubhead={this.props.addressSectionSubhead}
          cardSectionSubhead={this.props.cardSectionSubhead || "Billing"}
          cardSectionDek={this.renderSelectExistingProfileCTA()}
          submitButtonText={{
            default: "Save",
            submitting: "Saving...",
          }}
          formElementNames={formElementNames}
          updateElementAction={paymentMethodsActions.updateElement}
          submitSubformAction={this.onPaymentSubmit}
          makeDefaultCheckbox={makeDefaultCheckbox}
          shouldClearFieldsOnUnmount={true}
          shouldClearFieldsOnMount={this.props.shouldClearFieldsOnMount}
          clearFieldsAction={paymentMethodsActions.clearFields}
          isEdit={this.state.display === displayStates.EDIT_CARD}
          paymentProfile={paymentProfile}
          handleFormElementValuesCallback={this.props.handleFormElementValuesCallback}
          setExistingValuesAction={paymentMethodsActions.setExistingValues}
          displayBillingSameAsShippingCheckbox={this.props.displayBillingSameAsShippingCheckbox}
          toggleBillingSameAsShipping={this.props.toggleBillingSameAsShipping}
          validateBeforeSubmitAction={this.props.validateBeforeSubmitAction}
        />
      </div>
    );
  }

  render() {
    return <div className={`molecule-payment-profile-select ${this.optionalClasses()}`}>{this.content()}</div>;
  }
}

const mapStateToProps = state => {
  const { paymentProfiles, isPaymentProfilesLoading, updatingPaymentMethods } = state;

  const blockForMasquerade = blockForMasqueradeSelector(state);

  return {
    paymentProfiles,
    isPaymentProfilesLoading,
    updatingPaymentMethods,
    blockForMasquerade,
    userDeletePaymentMethodsFF: selectFeatureFlagEnabled(featureFlags.USER_DELETE_PAYMENT_METHODS)(state),
  };
};

const MoleculePaymentProfileSelect = compose(
  connect(mapStateToProps, null),
  withFlagsAndExperiments(flagsAndExperimentNames.COMET_SF_ENABLE_RECURRING_BILLING)
)(MoleculePaymentProfileSelectComponent);

export default MoleculePaymentProfileSelect;
